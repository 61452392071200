import React, {useEffect, useMemo} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationProvider,} from "react-bootstrap-table2-paginator";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as actions from "./redux/usersListActions";
import {
    getHandlerTableChange,
    getSelectRow,
    headerSortingClasses,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
} from "../../../../_metronic/_helpers";
import {Pagination} from "../../../../_metronic/_partials/controls";
import {defaultSorted, sizePerPageList} from "../../ui-helpers/ListUIHelpers";
import {useUserUIContext} from "./UsersUIContext";
import {ActionsColumnFormatter} from "../../column-formatters/ActionsColumnFormatter";
import {StatusColumnFormatter} from "../../column-formatters/StatusColumnFormatter";
import {RoleColumnFormatter} from "../../column-formatters/RoleColumnFormatter";

export function UsersTable() {
    // Customers UI Context
    const userUIContext = useUserUIContext();
    const userUIProps = useMemo(() => {
        return {
            ids: userUIContext.ids,
            setIds: userUIContext.setIds,
            queryParams: userUIContext.queryParams,
            setQueryParams: userUIContext.setQueryParams,
            openEditCustomerDialog: userUIContext.openEditCustomerDialog,
            openDeleteCustomerDialog: userUIContext.openDeleteCustomerDialog,
        };
    }, [userUIContext]);

    // Getting curret state of customers list from store (Redux)
    const {currentState} = useSelector(
        (state) => ({currentState: state.listUsers}),
        shallowEqual
    );
    const {totalCount, entities, listLoading} = currentState;

    // Customers Redux state
    const dispatch = useDispatch();
    useEffect(() => {
        // clear selections list
      userUIProps.setIds([]);
        // server call by queryParams
        dispatch(actions.fetchUsers(userUIProps.queryParams));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userUIProps.queryParams, dispatch]);
    // Table columns
    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            hidden: true
        },
        {
            dataField: "name",
            text: "Firstname",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "lastName",
            text: "Lastname",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "active",
            text: "Status",
            sort: true,
            sortCaret: sortCaret,
            formatter: StatusColumnFormatter,
            headerSortingClasses,
        },
        {
            dataField: "role",
            text: "Role",
            sort: true,
            sortCaret: sortCaret,
            formatter: RoleColumnFormatter,
            headerSortingClasses,
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionsColumnFormatter,
            formatExtraData: {
                openEditCustomerDialog: userUIProps.openEditCustomerDialog,
                openDeleteCustomerDialog: userUIProps.openDeleteCustomerDialog,
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },
    ];
    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: sizePerPageList,
        sizePerPage: userUIProps.queryParams.pageSize,
        page: userUIProps.queryParams.pageNumber,
    };
    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({paginationProps, paginationTableProps}) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                bordered={false}
                                classes="table table-head-custom table-vertical-center"
                                bootstrap4
                                remote
                                keyField="id"
                                data={entities === null ? [] : entities}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                onTableChange={getHandlerTableChange(
                                    userUIProps.setQueryParams
                                )}
                                selectRow={getSelectRow({
                                    entities,
                                    ids: userUIProps.ids,
                                    setIds: userUIProps.setIds,
                                })}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities}/>
                                <NoRecordsFoundMessage entities={entities}/>
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
}
