import React from "react";
import {useSubheader} from "../../../../_metronic/layout";
// import {Route} from "react-router-dom";
import {UsersUIProvider} from "./UsersUIContext";
import {UsersCard} from "./UsersCard";
// import {UserEditDialog} from "./UserEditDialog";

export function UsersPage({history}) {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Users");
    const usersUIEvents = {
        newUserButtonClick: () => {
            history.push("/oreo-settings/users/new");
        }
    };

    return (
        <UsersUIProvider usersUIEvents={usersUIEvents}>
            {/*<Route path="/oreo-settings/users/new">*/}
            {/*    {({history, match}) => (*/}
            {/*        <UserEditDialog*/}
            {/*            show={match != null}*/}
            {/*            onHide={() => {*/}
            {/*                history.push("/oreo-settings/users");*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    )}*/}
            {/*</Route>*/}
            <UsersCard/>
        </UsersUIProvider>
    );
}
