import React from "react";
import {UserStatusCssClasses, UserStatusTitles} from "../ui-helpers/ListUIHelpers";


export function StatusColumnFormatter(cellContent, row) {
    const getLabelCssClasses = () => {
        return `label label-lg label-light-${
            UserStatusCssClasses[row.active]
        } label-inline`;
    };
    return (
        <span className={getLabelCssClasses()}>
      {UserStatusTitles[row.active]}
    </span>
    );
}
