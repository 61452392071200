import * as requestFromServer from "./usersListCrud";
import {callTypes, usersListSlice} from "./usersListSlice";

const {actions} = usersListSlice;

export const fetchUsers = queryParams => dispatch => {
    dispatch(actions.startCall({callType: callTypes.list}));
    return requestFromServer
        .getAllUsers(queryParams)
        .then(response => {
            const {totalCount, entities} = response.data;
            dispatch(actions.usersFetched({totalCount, entities}));
        })
        .catch(error => {
            error.clientMessage = "Can't find customers";
            dispatch(actions.catchError({error, callType: callTypes.list}));
        });
};

