import React, {useMemo} from "react";
import {Card, CardBody, CardHeader, CardHeaderToolbar,} from "../../../../_metronic/_partials/controls";
import {useUserUIContext} from "./UsersUIContext";
import {UsersTable} from "./UsersTable";

export function UsersCard() {
    const usersUIContext = useUserUIContext();
    const usersUIProps = useMemo(() => {
        return {
            ids: usersUIContext.ids,
            newUserButtonClick: usersUIContext.newUserButtonClick,
        };
    }, [usersUIContext]);

    return (
        <Card>
            <CardHeader title="Users List">
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={usersUIProps.newUserButtonClick}
                    >
                        New User
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                {/*<CustomersFilter />*/}
                {/*{customersUIProps.ids.length > 0 && <CustomersGrouping />}*/}
                <UsersTable />
            </CardBody>
        </Card>
    );
}
