import React from "react";


export function RoleColumnFormatter(cellContent, row) {
    const getLabelCssClasses = () => {
        return `label label-lg label-light-${
            "info"
        } label-inline`;
    };
    return (
        Object.values(row.role).map((value, index) => (
            <span key={index}>
                <span className={getLabelCssClasses()}>
                    {value.role}
                </span>
                &nbsp;
            </span>
        ))
    );
}
