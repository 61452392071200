import React, {Suspense} from "react";
import {Redirect, Switch} from "react-router-dom";
import {ContentRoute, LayoutSplashScreen} from "../_metronic/layout";
import {DashboardPage} from "./pages/dashboard/DashboardPage";
import {UsersPage} from "./pages/settings/users/UsersPage";

export default function BasePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/oreo-settings/users" component={UsersPage}/>

                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
