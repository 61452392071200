import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {UsersListInitialFilter} from "../../ui-helpers/ListUIHelpers";

const UsersUIContext = createContext();

export function useUserUIContext() {
  return useContext(UsersUIContext);
}

export function UsersUIProvider({usersUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(UsersListInitialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initUser = {
    id: undefined,
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    gender: "Female",
    status: 0,
    dateOfBbirth: "",
    ipAddress: "",
    type: 1
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initUser,
    newUserButtonClick: usersUIEvents.newUserButtonClick,
    // openEditCustomerDialog: customersUIEvents.openEditCustomerDialog,
    // openDeleteCustomerDialog: customersUIEvents.openDeleteCustomerDialog,
    // openDeleteCustomersDialog: customersUIEvents.openDeleteCustomersDialog,
    // openFetchCustomersDialog: customersUIEvents.openFetchCustomersDialog,
    // openUpdateCustomersStatusDialog: customersUIEvents.openUpdateCustomersStatusDialog
  };

  return <UsersUIContext.Provider value={value}>{children}</UsersUIContext.Provider>;
}
