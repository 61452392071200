import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/pages/auth/redux/authRedux";
import {usersListSlice} from "../app/pages/settings/users/redux/usersListSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  listUsers: usersListSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
